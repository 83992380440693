import React, { useEffect } from "react";
import PricingAView from "./PricingA.view";
import { defaultData } from "./PricingA.model";
import UseComponentVisibility from "@/hooks/useComponentVisibility";
import useUserCourseStatusAction from "@/store/actions/userCourseStatusAction";
import { toast } from "react-toastify";
import useAuthActions from "@/store/actions/authAction";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import useCourseStatusActionMapping from "../../Hooks/useCourseStatusActionMapping/useCourseStatusActionMapping.controller";
import CourseLeadSourceEnum from "@/outscal-commons-frontend/Enums/CourseLeadSourceEnum";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import { DefaultCourseData } from "@/utils/LMSUtils";
UserCourseService.getInstance();

if (!UserCourseService.instance?.course?.slug) {
  UserCourseService.instance.setupCourse(
    DefaultCourseData.course,
    DefaultCourseData.courseFromStrapi
  );
}

const PricingA = ({
  section_id,
  showOn,
  heading = defaultData.heading,
  subHeading = defaultData.subHeading,
  listData = defaultData.listData,
  showCallAdvisor = true,
  showCards = "ALL",
}) => {
  const { user } = useUserActions();
  const { auth } = useAuthActions();
  const { userCourseStatus } = useUserCourseStatusAction();
  const { currentStatus = null, coursePermissions = [] } =
    userCourseStatus || {};
  const { course = {}, courseFromStrapi = {} } =
    UserCourseService.instance || {};

  const { takeGivenAction } = useCourseStatusActionMapping(course);

  useEffect(() => {
    if (user) {
      UserCourseService.instance.setUpUserCourseController(user);
    }
  }, [user?.userDetails?.user_access?.type]);

  return (
    <UseComponentVisibility conditions={showOn}>
      <PricingAView
        id="pricing"
        analyticsId={`pricing_${section_id}`}
        showCards={showCards}
        showCallAdvisor={showCallAdvisor}
        heading={heading}
        permissions={coursePermissions}
        subHeading={subHeading}
        listData={listData}
        amountData={courseFromStrapi?.amountData}
        isLoggedIn={auth?.token}
        contactAdvisorPlugData={{
          heading: "Talk to a Career Advisor",
          subheading:
            "Transform Your Passion into Profession with Our Comprehensive Courses for Aspiring Game Developers.",
          analyticsName: "course-career-advisor",
          action: {
            text: "Reach Out To Us",
            action: () => {
              if (auth?.token) {
                if (currentStatus != null) {
                  toast.info(
                    "We have already received your call request. We will contact you soon."
                  );
                } else {
                  takeGivenAction({
                    name: "update-status",
                    params: {
                      courseSlug: course.slug,
                      waitlistSource: CourseLeadSourceEnum.getValue(
                        "career-advisor-form"
                      ),
                    },
                  });
                  toast.success(
                    "We have received your application. We will contact you soon."
                  );
                }
              } else {
                takeGivenAction({
                  name: "logout",
                  params: {
                    popupHeading: "Contact Career Advisor",
                    waitlistSource: CourseLeadSourceEnum.getValue(
                      "career-advisor-form"
                    ),
                  },
                });
              }
            },
          },
        }}
      />
    </UseComponentVisibility>
  );
};

export default PricingA;
