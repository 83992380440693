export const MAX_ASSIGNMNET_SUBMISSION_ALLOWED = 100;

export const removePatternsFromMaterialTitle = (title) => {
  title = title.replace(
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu,
    ""
  );

  title = title.replace(/^\[?(content|assignment|quiz)\]?\s*-\s*/gi, "");

  return title.trim();
};

export const materialTypeIcon = {
  assignment: "MdEditDocument",
  quiz: "MdOutlineQuestionAnswer",
  content: "FaBookOpenReader",
};

export const saveUrlToLocal = (url) => {
  localStorage.setItem("modulesPath", url);
};

export const getIdsFormSlugs = ({
  course = { modules: {} },
  moduleSlug,
  chapterSlug,
  materialSlug,
}) => {
  let moduleId = "",
    chapterId = "",
    materialId = "";

  const module = course.modules[moduleSlug];
  moduleId = module?._id;
  if (module?.chapters && chapterSlug) {
    chapterId = module?.chapters[chapterSlug]?._id;
  }
  if (
    module?.chapters &&
    chapterSlug &&
    module?.chapters[chapterSlug] &&
    module?.chapters[chapterSlug]?.materials &&
    materialSlug
  ) {
    materialId = module?.chapters[chapterSlug]?.materials[materialSlug]?._id;
  }

  return { moduleId, chapterId, materialId };
};

export const DefaultCourseData = {
  course: {
    slug: "subscription-course",
    title: "Projects",
  },
  courseFromStrapi: {
    amountData: {
      CONTENT: {
        amount: 29.99,
      },
    },
  },
};
