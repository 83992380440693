import React from "react";
import styles from "./PricingCard.module.css";
import layoutStyles from "@/styles/LayoutStyles.module.css";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { convertStringToSlug } from "@/outscal-commons-frontend/utils/stringOperations";
import CourseStatusPricingButton from "./CourseStatusPricingButton/CourseStatusPricingButton.controller";
import useCourseStatusActionMapping from "../../Hooks/useCourseStatusActionMapping/useCourseStatusActionMapping.controller";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import useAuthActions from "@/store/actions/authAction";
import useUserCourseStatusAction from "@/store/actions/userCourseStatusAction";

const PricingActions = ({
  price,
  heading,
  paymentType,
  subHeading,
  analyticsId,
  showEmiOption,
  emiPlanText,
  pricingActionSubText,
  beforePurchaseText,
  afterPurchaseText,
  beforePurchaseProText,
  link,
  courseType,
}) => {
  const { user } = useUserActions();
  const { auth } = useAuthActions();
  const { userCourseStatus } = useUserCourseStatusAction();
  const { takeGivenAction } = useCourseStatusActionMapping(
    UserCourseService.instance?.course,
    paymentType
  );

  const onEMIPlanClick = () => {
    let action;
    if (
      courseType == "PAID-PROJECT" &&
      (paymentType == "MENTORSHIP" ||
        !userCourseStatus?.coursePermissions?.length)
    ) {
      action =
        UserCourseService.instance?.userCourseController.getSubscriptionAction(
          link,
          paymentType,
          auth?.token ? true : false
        );
    } else {
      action =
        UserCourseService.instance?.userCourseController.getEMIAction(auth);
    }

    if (action) {
      takeGivenAction(action);
    }
  };

  return (
    <div
      className={layoutStyles["col-div"]}
      style={{ "--margin": "auto 0 0 0", "--gap": "8px" }}
    >
      {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
      {showEmiOption && (
        <p
          onClick={() => onEMIPlanClick()}
          className={styles.cta2}
          data-analytics={[
            UIElements.BUTTON,
            `${
              analyticsId ? `${analyticsId}_` : ""
            }secondary_cta_click_${convertStringToSlug(heading)}`,
          ]}
        >
          {emiPlanText}
        </p>
      )}
      <CourseStatusPricingButton
        analyticsId={`${analyticsId ? `${analyticsId}_` : ""}${paymentType}`}
        price={price}
        paymentType={paymentType}
        beforePurchaseText={beforePurchaseText}
        afterPurchaseText={afterPurchaseText}
        beforePurchaseProText={beforePurchaseProText}
        link={link}
        courseType={courseType}
        pricingActionSubText={pricingActionSubText}
      />
    </div>
  );
};

export default PricingActions;
