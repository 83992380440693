import React from "react";
import styles from "./PricingCard.module.css";
import FeaturesList from "./FeaturesList.controller";
import PriceDetails from "./PriceDetails.controller";
import PricingActions from "./PricingActions.controller";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const PricingCardView = ({
  paymentType,
  heading,
  permissions,
  tagText,
  currency = "$",
  price,
  priceMeta,
  features,
  subHeading,
  showEmiOption,
  analyticsId,
  beforePurchaseText,
  afterPurchaseText,
  beforePurchaseProText,
  emiPlanText,
  pricingActionSubText,
  hasCourseProAccess,
  amount,
  amountSubscriptText,
  pricingSubText,
  link,
  onLinkCardClick,
  courseType,
}) => {
  return (
    <div
      onClick={onLinkCardClick}
      className={styles.root}
      view-analytics={[UIElements.CARD, paymentType]}
    >
      <p className={styles.heading}>{heading.toUpperCase()}</p>
      <PriceDetails
        currency={currency}
        price={price}
        priceMeta={priceMeta}
        paymentType={paymentType}
        hasCourseProAccess={hasCourseProAccess}
        amount={
          !permissions?.length || courseType == "PAID-PROJECT"
            ? amount
            : undefined
        }
        pricingSubText={!permissions?.length ? pricingSubText : undefined}
        amountSubscriptText={
          !permissions?.length ? amountSubscriptText : undefined
        }
      />
      {tagText && <p className={styles["text-tag"]}>{tagText}</p>}
      <div className={styles["features-list-wrapper"]}>
        <FeaturesList features={features} />
      </div>
      <PricingActions
        paymentType={paymentType}
        permissions={permissions}
        price={price}
        heading={heading}
        subHeading={subHeading}
        analyticsId={analyticsId}
        showEmiOption={showEmiOption}
        beforePurchaseText={beforePurchaseText}
        afterPurchaseText={afterPurchaseText}
        beforePurchaseProText={beforePurchaseProText}
        emiPlanText={emiPlanText}
        pricingActionSubText={pricingActionSubText}
        link={link}
        courseType={courseType}
      />
    </div>
  );
};

export default PricingCardView;
