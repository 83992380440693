import React from "react";
import CourseStatusPricingButtonView from "./CourseStatusPricingButton.view";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import useUserCourseStatusAction from "@/store/actions/userCourseStatusAction";
import useCourseStatusActionMapping from "@/mvcComponents/CommonUI/Hooks/useCourseStatusActionMapping/useCourseStatusActionMapping.controller";
import useAuthActions from "@/store/actions/authAction";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import UserAccessTypeEnum from "@/outscal-commons-frontend/Enums/userAccessTypeEnum";

const CourseStatusPricingButton = ({
  analyticsId,
  price,
  paymentType,
  beforePurchaseText,
  afterPurchaseText,
  beforePurchaseProText,
  link,
  courseType,
  pricingActionSubText,
}) => {
  const { auth } = useAuthActions();
  const { userCourseStatus } = useUserCourseStatusAction();
  const { user } = useUserActions();
  const { takeGivenAction } = useCourseStatusActionMapping(
    UserCourseService.instance?.course,
    paymentType
  );

  const currentStatus = user?.userCourseStatus?.find(
    (status) => status?.course?.slug == UserCourseService.instance?.course?.slug
  );

  const takeAction = () => {
    let action;

    if (
      courseType == "PAID-PROJECT" &&
      (paymentType == "MENTORSHIP" ||
        !userCourseStatus?.coursePermissions?.length)
    ) {
      action =
        UserCourseService.instance?.userCourseController?.getSubscriptionAction(
          link,
          paymentType,
          auth?.token ? true : false
        );
    } else {
      action =
        UserCourseService.instance?.userCourseController.getPricingButtonAction(
          userCourseStatus.coursePermissions,
          paymentType,
          user
        );
    }

    if (action) {
      takeGivenAction(action);
    }
  };

  return (
    <>
      <CourseStatusPricingButtonView
        userCourseStatus={{
          text:
            UserCourseService.instance?.userCourseController?.getPricingButtonText(
              currentStatus?.permissions,
              paymentType,
              beforePurchaseText,
              afterPurchaseText,
              beforePurchaseProText
            ) || beforePurchaseText,
          action: takeAction,
        }}
        analyticsId={analyticsId}
        price={price}
        authToken={auth?.token}
        hasCourseProAccess={
          UserCourseService.instance?.courseFromStrapi?.subscriptionAccess ==
          "PRO"
        }
        isProUser={
          user?.userDetails?.user_access?.type ==
          UserAccessTypeEnum.getValue("pro-trial-user")
        }
        paymentType={paymentType}
        pricingActionSubText={pricingActionSubText}
      />
    </>
  );
};

export default CourseStatusPricingButton;
