import React from "react";
import styles from "../PricingCard.module.css";
import ProTag from "@/mvcComponents/Commons/ProTag/ProTag.controller";
import AnalyticsManager from "@/outscal-commons-frontend/Managers/Analytics/AnalyticsManager";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import { useRouter } from "next/router";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";

const CourseStatusPricingButtonView = ({
  isProUser,
  userCourseStatus,
  analyticsId,
  paymentType,
  price,
  hasCourseProAccess,
  pricingActionSubText,
}) => {
  const router = useRouter();
  const { getActiveCourseSubscription, user } = useUserActions();

  return userCourseStatus?.text == "Enrolled" ? (
    <>
      <span className={styles.ctaText}>
        <DynamicIcon icon="FiCheckCircle" size={14} />
        {getActiveCourseSubscription(user) &&
        (UserCourseService?.instance?.course?.slug == "subscription-course" ||
          UserCourseService?.instance?.courseFromStrapi?.courseType ==
            "PAID-PROJECT")
          ? "Trial Started"
          : userCourseStatus?.text}
      </span>
      {getActiveCourseSubscription(user) &&
        (UserCourseService?.instance?.course?.slug == "subscription-course" ||
          UserCourseService?.instance?.courseFromStrapi?.courseType ==
            "PAID-PROJECT") && (
          <div
            className={`${styles.cta}`}
            onClick={(e) => {
              AnalyticsManager.clickEvent(e);
              ClientAppManager.instance.analyticsService.captureUserEvent({
                ShownInterest: "UPGRADE-PLAN",
              });
              router.push("/payment/subscription");
            }}
            data-analytics={[UIElements.BUTTON, analyticsId]}
          >
            <ProTag fontSize="18px" padding="4px 4px 0 0" />
            Upgrade Plan
          </div>
        )}
    </>
  ) : (
    <>
      <div
        className={`${styles.cta}`}
        onClick={(e) => {
          AnalyticsManager.clickEvent(e);
          userCourseStatus.action();
        }}
        data-analytics={[UIElements.BUTTON, analyticsId]}
      >
        <ProTag fontSize="18px" padding="4px 4px 0 0" />
        {userCourseStatus?.text}
      </div>
      {pricingActionSubText && (
        <p className={styles.pricingActionSubText}>{pricingActionSubText}</p>
      )}
    </>
  );
};

export default CourseStatusPricingButtonView;
